<template>
  <div id="app">
    <router-view />
  </div>
</template>

<!-- <script src="https://res2.wx.qq.com/open/js/jweixin-1.6.0.js"></script> -->

<script>
import { Toast } from "vant";
export default {
  name: "App",
  data() {
    return {};
  },
  mounted() {
    this.getQRCode();
  },
  methods: {
    getQRCode() {
      console.log('进入签名获取扫一扫功能');
      if (this.getIsWxClient()) {
        // 当前页面url
        let url = location.href.split("#")[0];
        this.$axios
          // 请求签名
          .get("https://order.wxh5r.cn:7878/api/wx/get_sign_package/", {
            params: {
              url: url,
            },
          })
          .then((res) => {
            const { appId, nonceStr, signature, timestamp } = res.data;
            // console.log("???????????", appId, nonceStr, signature, timestamp);
            wx.config({
              debug: false,
              appId: appId,
              nonceStr: nonceStr,
              signature: signature,
              timestamp: timestamp,
              jsApiList: ["scanQRCode"],
            });
            wx.ready(function (res) {
              console.log("wxLoad:微信扫码验证成功++++", res);
              // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
            });
            wx.error(function (res) {
              console.log("wxLoad:微信扫码验证失败----", res);
              // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
            });
          });
      }else{
        Toast("请用微信打开连接，才可使用扫一扫");
      }
    },
    /**
     * 判断是否是微信环境
     */
    getIsWxClient() {
      // 判断是否是微信环境
      var ua = navigator.userAgent.toLowerCase();
      console.log('判断是否是微信环境',ua);
      // match() 方法可在字符串内检索指定的值，或找到一个或多个正则表达式的匹配。
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      }
      return false;
    },
  },
};
</script>
<style>
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}
</style>


