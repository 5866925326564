import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  // {
  //   path: '*',
  //   redirect: '/goods'
  // },
  {
    path: '/',
    name: 'index',
    component: ()=> import('./view/index/index')
  },
  {
    name: 'ceshil',
    component: () => import('./view/index/ceshil'),
    meta: {
      title: '扫一扫'
    }
  },
 /* {
    name: 'scan',
    component: () => import('./view/index/scan'),
    meta: {
      title: '扫一扫2'
    }
  },
   {
    name: 'weixin_login',
    component: () => import('./view/weixin_login'),
    meta: {
      title: '微信登录'
    }
  } */
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
