import axios from 'axios'
import { Notify, Toast } from 'vant'

// const whitelist = ['/store_deposit/wechat_user_info'] // 不用进行授权白名单

// 创建 axios 实例
const requests = axios.create({
  // baseURL: process.env.VUE_APP_API, // 基础url,如果是多环境配置这样写，也可以像下面一行的写死。
  baseURL: '/',
  // baseURL: 'http://localhost:8080/',
  timeout: 60000 // 请求超时时间
})

// 错误处理函数
const err = (error) => {
  console.log('错误处理函数',error);
  Notify({ type: 'danger', message: error.message })
  return Promise.reject(error)
}

// request interceptor(请求拦截器)
requests.interceptors.request.use((config) => {
 /* 
  //   const token = Vue.ls.get(ACCESS_TOKEN)
  const token = localStorage.getItem('token')
  const openid = localStorage.getItem('openid')
  if (token) {
    config.headers['token'] = token
    config.headers['openid'] = openid
  } else {
    // 没有登录信息
    goWexin(config.url, '正在登录中...')
  } 
  */
 console.log('请求拦截器',config);
  Toast.loading({
    message: "加载中",
    duration: 2000, // toast不消失
  });
  return config
}, err)

// response interceptor（接收拦截器）
requests.interceptors.response.use((response) => {
  Toast.clear();
  const res = response.data
  console.log('interceptor（接收拦截器）',res);
  // if (res.code !== 200) {
  if (res.code !== 0) {

    if (res.code == 204) {
      // 登录异常
      // goWexin(response.url, '正在重新登录中...')
    } else {
      Notify({ type: 'danger', message: res.message || res.msg })
    }
    return Promise.reject(res)
  } else {
    return res
  }
}, err)
/* 
// 微信授权
let getWexinFlag = false
function goWexin(url, message) {
  if (!whitelist.includes(url) && !getWexinFlag) {
    getWexinFlag = true
    Notify({ type: 'warning', message })
    localStorage.clear()
    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3435ee5a437e390b&redirect_uri=${encodeURIComponent(
      'http://dmp.huwaishequ.com/shop-cash-manage'
    )}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
  }
}
 */
export default {
  requests
}
