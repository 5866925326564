import Vue from 'vue';
import App from './App';
import { router } from './router';
import axios from './utils/axios';
import { Popup } from 'vant'
Vue.use(Popup)
Vue.prototype.$axios = axios.requests


// function geiWeiXin(){
//   const redirect_uri = encodeURIComponent('https://warehouse.wxh5r.cn/')
//   console.log('>>>>>>>>>>>>',redirect_uri);
//   location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7598ac265e3a9bf5&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
// }





new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
// geiWeiXin()
